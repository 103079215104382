import { ReactElement, PropsWithChildren } from 'react';

import { Icon as UIIcon } from '@breathelife/ui-components';
import { t } from 'i18next';

import { ComprehensivePricingProductContainer } from './Styles';

import Typography, { ItalicTypography } from '../../../Typography';
import { Box, Tooltip } from '@breathelife/mui';
import { ComprehensivePricingCurrency, IconName } from '@breathelife/types';

import { Icon } from '../../../Icons/Icon';
import { useTheme } from '../../../../Styles/themed-styled-components';
import { costToCostWithCurrency } from './helpers';

export enum ComprehensivePricingWidgetTooltipType {
  ado = 'ado',
  policyFee = 'policyFee',
}
function ComprehensivePricingWidgetTooltip(props: { type: ComprehensivePricingWidgetTooltipType }): ReactElement {
  const theme = useTheme();
  let message: string = '';
  switch (props.type) {
    case ComprehensivePricingWidgetTooltipType.ado:
      message = t('assistedApplication.comprehensivePricingWidget.adoTooltipText');
      break;
    case ComprehensivePricingWidgetTooltipType.policyFee:
      message = t('assistedApplication.comprehensivePricingWidget.policyFeeTooltipText');
      break;
  }

  return (
    <Tooltip title={message}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon name='info' width='16px' height='16px' stroke={theme.colors.primary[40]} />
      </Box>
    </Tooltip>
  );
}

type ComprehensivePricingWidgetProductCardProps = {
  displayName: string;
  type: 'product' | 'rider' | 'subTotal';
  cost?: number;
  isJointProduct?: boolean;
  coverageAmount?: number;
  currency: ComprehensivePricingCurrency;
  isDuplicateCost?: boolean;
  isFirstInList?: boolean;
  comprehensivePricingWidgetTooltipType?: ComprehensivePricingWidgetTooltipType;
};

export function ComprehensivePricingWidgetProductCard(
  props: PropsWithChildren<ComprehensivePricingWidgetProductCardProps>,
): ReactElement {
  const theme = useTheme();
  const displayedCost = props.isDuplicateCost ? '-' : costToCostWithCurrency(props.cost || 0, props.currency);

  return (
    <Box mt={props.isFirstInList ? 0 : props.type === 'rider' ? 1 : 2} ml={props.type === 'rider' ? 2 : 0}>
      <ComprehensivePricingProductContainer>
        <div>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant={props.type === 'subTotal' ? 'h4' : 'body1'} component='span'>
              {props.displayName}
            </Typography>
            {props.isJointProduct && (
              <Box sx={{ marginLeft: '4px', display: 'flex', alignItems: 'center', paddingTop: '2px' }}>
                <UIIcon name={IconName.group} size='20px' color={{ primary: theme.colors.grey[70] }} />
              </Box>
            )}
            {props.comprehensivePricingWidgetTooltipType && (
              <Box ml={1}>
                <ComprehensivePricingWidgetTooltip type={props.comprehensivePricingWidgetTooltipType} />
              </Box>
            )}
          </Box>
          {props.coverageAmount && (
            <div>
              <ItalicTypography variant='small1' grey={70} noWrap>
                {costToCostWithCurrency(props.coverageAmount, props.currency, true)}
              </ItalicTypography>
            </div>
          )}
        </div>
        {(props.cost || props.cost === 0) && (
          <Box pl={3}>
            <Typography variant={props.type === 'subTotal' ? 'h4' : 'body1'} noWrap>
              {displayedCost}
            </Typography>
          </Box>
        )}
      </ComprehensivePricingProductContainer>
      {props.children && <div>{props.children}</div>}
    </Box>
  );
}
